import { of, concat, } from 'rxjs';
import { last, } from 'ramda';

import { actions, } from '@ezugi/bootstrap';
import { GAME_RESULT, } from '@ezugi/constants';

const {
  roundActions: { round, },
  gameActions: { game, },
} = actions;

export default function handleGameResult(socketMessage) {
  const { roundId, timeStamp, WinAmount, GameResults, LastWinners, } = socketMessage;
  return concat(
    of(
      game.set({
        gameResults: GameResults,
        lastWin: WinAmount,
      })
    ),
    of(
      round.set({
        roundStatus: GAME_RESULT,
        roundId,
        winAmount: WinAmount,
        gameResults: GameResults,
        timestamp: timeStamp,
        winnerList: LastWinners,
        winningNumber: last(GameResults),
      })
    )
  );
}
