import { actions, } from '@ezugi/bootstrap';

import { ANTE, CALL, } from '../../../constants/betTypes';

const {
  gameActions: { game, },
} = actions;

export default function handleWonBets({ WinningBets, }) {
  return (gameResults) => {
    const wonSidebets = Object.entries(WinningBets).reduce(
      (acc, [ betType, value, ]) => ([ ANTE, CALL, ].includes(betType) ? [] : [ ...acc, { ...value, betType, }, ]),
      []
    );

    return game.set({
      gameResults: {
        ...gameResults,
        wonSidebets,
        winningBets: WinningBets,
      },
    });
  };
}
