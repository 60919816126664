import { of, concat, } from 'rxjs';

import cardsActions from '../../actions/cards';
import { playerCardsSelector, dealerCardsSelector, } from '../../selectors/cards';
import { PLAYER_HAND, } from '../../../constants/strings';

function updateCards(card) {
  if (this.length === 3) {
    const idx = this.findIndex((c) => c.CardName === 'ww');
    return [ ...this.slice(0, idx), card, ...this.slice(idx + 1), ].slice(-3);
  }

  return [ ...this, card, ];
}

export default function handleCardMessage(socketMessage, state) {
  const { CardHand, CardName, } = socketMessage;

  const { cards, } = CardHand === PLAYER_HAND ? playerCardsSelector(state) : dealerCardsSelector(state);

  return concat(
    of(
      cardsActions.cards.set({
        [CardHand]: { cards: updateCards.call(cards, { CardName, }), },
      })
    )
  );
}
