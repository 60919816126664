import { of, } from 'rxjs';

import { actions, } from '@ezugi/bootstrap';

const {
  roundActions: { round, },
} = actions;

export default function handleFirstCardHands(socketMessage) {
  const { PlayerHand, PlayerHandCards, PlayerHandStrengthCards, SidebetResult, } = socketMessage;
  return of(
    round.set({
      firstCardHands: { PlayerHand, PlayerHandCards, PlayerHandStrengthCards, SidebetResult, },
    })
  );
}
