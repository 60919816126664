import { handleActions, } from 'redux-actions';
import { actions, } from '@ezugi/bootstrap';
import { omit, } from 'ramda';

import { getTotalBet, } from '../../epics/bets/utils';
import * as BETS from '../../../constants/betTypes';

const { betActions, } = actions;

export const INITIAL_STATE = {
  current: {},
  totalBet: 0,
  decision: null,
};

INITIAL_STATE.history = [ { ...INITIAL_STATE, }, ];

export default handleActions(
  {
    [betActions.bet.call]: (state) => ({
      ...state,
      current: { ...state.current, [BETS.CALL]: state.current[BETS.ANTE], },
      totalBet: state.totalBet + ((state.current[BETS.ANTE] || {}).value || 0),
      decision: BETS.DECISION.CALL,
    }),
    [betActions.bet.fold]: (state) => ({
      ...state,
      current: omit([ BETS.ANTE, ])(state.current),
      totalBet: state.totalBet - ((state.current[BETS.ANTE] || {}).value || 0),
      decision: BETS.DECISION.FOLD,
    }),
    [betActions.bet.set]: ({ history, ...state }, { payload: { type, value, ...rest }, }) => {
      const current = {
        ...state.current,
        [type]: { value: ((state.current[type] || {}).value || 0) + value, ...rest, },
      };
      const t = getTotalBet(current);

      return {
        ...state,
        current,
        history: [ ...history, { ...state, current, totalBet: t, }, ],
      };
    },
    [betActions.bet.reset]: (state) => ({
      ...state,
      ...INITIAL_STATE,
    }),
    [betActions.bet.clear]: (state) => ({
      ...state,
      ...INITIAL_STATE,
      history: [ ...state.history, INITIAL_STATE, ],
    }),
    [betActions.bet.cache]: (state, { payload, }) => ({
      ...state,
      last: payload,
    }),
    [betActions.bet.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
      history: [ ...state.history, payload, ],
    }),
    [betActions.history.reset]: (state) => ({
      ...state,
      history: [ { ...INITIAL_STATE, }, ],
    }),
    [betActions.history.apply]: (state, { payload, }) => ({
      ...state,
      ...payload,
    }),
    [betActions.totalBet.set]: (state, { payload: { value, }, }) => ({
      ...state,
      totalBet: value,
    }),
  },
  INITIAL_STATE
);
