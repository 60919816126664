import { combineEpics, ofType, } from 'redux-observable';
import { pluck, filter, map, } from 'rxjs/operators';
import { isNil, compose, not, } from 'ramda';
import { CALL_BETS_DECISION, } from '@ezugi/constants';
import { actions, selectors, } from '@ezugi/bootstrap';
import { fromEvent, } from 'rxjs';
import { THREE_CARD_POKER_PLAYER_DECISION, THREE_CARD_POKER_CARDS_DISPLAY_TOGGLE, } from './constants';

const {
  analyticsActions: { analytics, },
  socketActions: { socket, },
} = actions;

const { roundIdSelector, tableIdSelector, } = selectors;

/**
 * Send player decision event
 */
const playerDecisionEpic = (action$, state$) => action$.pipe(
  ofType(socket.message),
  pluck('payload'),
  filter((m) => [ CALL_BETS_DECISION, ].includes(m.MessageType)),
  map((m) => analytics.push({
    event: THREE_CARD_POKER_PLAYER_DECISION,
    decision: m.CallBetDecision,
    roundId: roundIdSelector(state$.value),
    tableId: tableIdSelector(state$.value),
  }))
);

/**
 * Send CardsDisplay/LeftSideDrawer toggle status
 * TODO: replace local `drawerState` variable that mirrors drawer state
 */
let drawerState = true;

const cardsDisplayToggleEpic = () => fromEvent(
  document,
  'click',
  (evt) => evt.target.closest('[data-e2e="left-side-drawer-button"]')
).pipe(filter(compose(not, isNil)), map(() => {
  drawerState = !drawerState;

  return analytics.push({
    event: THREE_CARD_POKER_CARDS_DISPLAY_TOGGLE,
    status: drawerState ? 'opened' : 'closed',
  });
}));

export default combineEpics(playerDecisionEpic, cardsDisplayToggleEpic);
