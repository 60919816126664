import { when, assoc, evolve, } from 'ramda';
import {
  HIGH_CARD,
  ONE_PAIR,
  THREE_OF_KIND,
  FOUR_OF_KIND,
  TWO_PAIRS,
  STRAIGHT,
  FLUSH,
  FULL_HOUSE,
  STRAIGHT_FLUSH,
  ROYAL_FLUSH,
} from '../../../constants/strings';
import * as BET_TYPES from '../../../constants/betTypes';

export const payoutsIndexMap = {
  0: HIGH_CARD,
  1: ONE_PAIR,
  2: TWO_PAIRS,
  3: THREE_OF_KIND,
  4: STRAIGHT,
  5: FLUSH,
  6: FULL_HOUSE,
  7: FOUR_OF_KIND,
  8: STRAIGHT_FLUSH,
  9: ROYAL_FLUSH,
};

const { ANTE_BONUS, PAIR_PLUS, SIX_CARD, } = BET_TYPES;

function hasPayout(payouts, payoutOption) {
  return () => !!+payouts[payoutOption];
}

function getPayoutObj(p, payouts, payoutOption) {
  return {
    index: +p.IndexID,
    strength: +payouts[payoutOption],
    payout: `${payouts[payoutOption]}:1`,
    name: payoutsIndexMap[p.IndexID],
  };
}

export function formatPayouts(list = []) {
  return list.reduce(
    (acc, p) => {
      const payouts = JSON.parse(p.payout);

      return evolve({
        [ANTE_BONUS]: when(
          hasPayout(payouts, 'option1'),
          assoc(payoutsIndexMap[p.IndexID], getPayoutObj(p, payouts, 'option1'))
        ),
        [PAIR_PLUS]: when(
          hasPayout(payouts, 'option2'),
          assoc(payoutsIndexMap[p.IndexID], getPayoutObj(p, payouts, 'option2'))
        ),
        [SIX_CARD]: when(
          hasPayout(payouts, 'option3'),
          assoc(payoutsIndexMap[p.IndexID], getPayoutObj(p, payouts, 'option3'))
        ),
      })(acc);
    },
    {
      General: {
        [BET_TYPES.ANTE]: {
          name: BET_TYPES.ANTE,
          index: 0,
          strength: 1,
          payout: '1:1',
        },
        [BET_TYPES.CALL]: {
          name: BET_TYPES.CALL,
          index: 1,
          strength: 0,
          payout: '1:1',
        },
      },
      [ANTE_BONUS]: {},
      [PAIR_PLUS]: {},
      [SIX_CARD]: {},
    }
  );
}
