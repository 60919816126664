import { of, EMPTY, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';
import { CALL_BETS, } from '@ezugi/constants';

import {
  THREE_OF_KIND, STRAIGHT, STRAIGHT_FLUSH, ROYAL_FLUSH,
} from '../../../constants/strings';

const {
  roundActions: { round, },
  notificationActions: { notification, },
} = actions;

export default function handleRoundStatus(socketMessage) {
  const { TimerTimeLeft, TimerTime, roundStartTimestamp, RoundTripStartTime, MessageType, } = socketMessage;
  return ({ videoDelay = 0, firstCardHands = {}, }) => {
    const timeLeft = +TimerTimeLeft - videoDelay;
    const totalTime = +TimerTime - videoDelay;

    switch (MessageType) {
    case CALL_BETS: {
      return of(
        ...[
          round.set({
            roundStatus: CALL_BETS,
            timeLeft: timeLeft < 0 ? 0 : timeLeft,
            totalTime,
            roundStartTimestamp,
            roundTime: RoundTripStartTime,
          }),
          ...([ THREE_OF_KIND, STRAIGHT, STRAIGHT_FLUSH, ROYAL_FLUSH, ].includes(firstCardHands.PlayerHand)
            ? [ notification.add({ message: 'notifications.play_for_ante_bonus_message', }), ]
            : []),
        ]
      );
    }
    // case CALL_BETS_DECISION: {
    //   return round.set({
    //     roundStatus: CALL_BETS_DECISION,
    //   });
    // }
    default: {
      return EMPTY;
    }
    }
  };
}
