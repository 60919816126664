import { of, } from 'rxjs';
import { map, omit, } from 'ramda';
import { LOADING, } from '@ezugi/constants';
import { actions, } from '@ezugi/bootstrap';
import { ls, } from '@ezugi/utils';

import {
  PLAYER_HAND, DEALER_HAND, NO_WELCOME_DIALOG, WELCOME_DIALOG,
} from '../../../constants/strings';
import cardsActions from '../../actions/cards';

const {
  socketActions: { socket, },
  videoActions: { video, },
  roundActions: { round, },
  settingsActions: { settings, },
  betActions: { bet, },
  dialogActions: { dialog, },
} = actions;
const [ err, noWelcomeDialog, ] = ls.get(NO_WELCOME_DIALOG);

export default function handleInitialData(socketMessage) {
  const {
    VideoStreamData,
    roundId,
    timeStamp,
    RoundTripStartTime,
    DealerCards,
    PlayerCards,
    PlayerHand,
    PlayerHandStrengthCards,
    playerBetOfThisRound,
  } = socketMessage;
  return of(
    ...[
      socket.success(socketMessage),
      settings.init(),
      video.set({ streamData: VideoStreamData, }),
      round.set({
        roundStatus: LOADING,
        roundId,
        roundTime: RoundTripStartTime,
        timestamp: timeStamp,
        firstCardHands: {
          PlayerHand,
          PlayerHandStrengthCards,
        },
      }),
      bet.apply({
        current: map((value) => ({ value, valid: true, }), {
          ...omit('SideBets', playerBetOfThisRound),
          ...playerBetOfThisRound.SideBets,
        }),
      }),
      cardsActions.cards.set({
        [DEALER_HAND]: { cards: DealerCards.map((CardName) => ({ CardName, })), },
        [PLAYER_HAND]: { cards: PlayerCards.map((CardName) => ({ CardName, })), },
      }),
      ...(err || !noWelcomeDialog ? [ dialog.add({ name: WELCOME_DIALOG, }), ] : []),
    ]
  );
}
