import { THREE_CARD_POKER_BET_TYPES, } from '@ezugi/constants';

export const {
  // newline
  ANTE,
  CALL,
  ANTE_BONUS,
  PAIR_PLUS,
  SIX_CARD,
} = THREE_CARD_POKER_BET_TYPES;

export const SIDEBETS = {
  PAIR_PLUS,
  SIX_CARD,
};

export const MAIN_BETS = {
  ANTE,
};

export const DECISION = {
  CALL: 'Call',
  FOLD: 'Fold',
  AUTO_FOLD: 'AutoFold',
};

export default { ...THREE_CARD_POKER_BET_TYPES, };
