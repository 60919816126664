import { CALL_BETS_DECISION, } from '@ezugi/constants';
import {
  betsEpic,
  betsReducer,
  socketEpic,
  cardsEpic,
  cardsReducer,
  payoutsEpic,
  analyticsEpic,
} from './store';
import { resultMessageClassName, } from './components/ResultMessage/styles';

export default () => ({
  components: {
    GameBoard: () => Promise.resolve({ default: () => null, }),
    VideoOverlay: () => import('./views/VideoOverlay'),
    LeftSideDrawerContent: () => import('./components/CardsDisplay'),
    CallBetsToolbar: () => import('./components/CallBetsToolbar'),
    WelcomeDialog: () => import('./components/WelcomeDialog'),
    SidebetsInfoDialog: () => import('./components/BetLayout/SidebetsInfoDialog'),
    Payouts: () => import('./components/Payouts'),
    GameInfo: () => import('./components/GameInfo'),
    StatusBar: () => import('./components/StatusBar'),
    ResultDisplay: () => import('./components/ResultDisplay'),
  },
  props: {
    GameLeftSideDrawer: { style: { top: '-20px', }, },
    GameResults: { hideWinnerList: true, },
    ResultMessage: { className: resultMessageClassName, },
    GameTools: { trailingNMB: true, },
  },
  store: {
    epics: [ socketEpic, betsEpic, cardsEpic, payoutsEpic, analyticsEpic, ],
    reducers: { bets: betsReducer, cards: cardsReducer, },
  },
  landscapeToolbarOrientation: 'vertical',
  sparseFooter: true,
  promptStates: [ CALL_BETS_DECISION, ],
  hideRightSideDrawer: true,
});
